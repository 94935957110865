import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { catchError, Observable, throwError, switchMap } from 'rxjs';
import {environment} from "../../../environments/environment";

export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const authService = inject(AuthService);

    // Clone the request object
    let newReq = req.clone();

    // Request
    if (authService.accessToken && !AuthUtils.isTokenExpired(authService.accessToken)) {
        newReq = req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + authService.accessToken),
        });
    }

    // Response
    return next(newReq).pipe(
        catchError((error) =>
        {
            if(error.url === `${environment.apiUrl}/users/refresh-token`) {
                // Sign out
                authService.signOut();
                return throwError(error);
            }

            // Catch "401 Unauthorized" responses
            if ( error instanceof HttpErrorResponse && error.status === 403 )
            {

                return authService.callRefreshToken().pipe(
                    switchMap(() => {
                        // console.log('next bearer' + authService.accessToken.slice(-10) )

                        const newReqWithToken = req.clone({
                            setHeaders: {
                                Authorization: `Bearer ${authService.accessToken}`
                            }
                        });
                        return next(newReqWithToken);
                    })
                );

            }
            if (error instanceof HttpErrorResponse && error.status === 401) {
                // Sign out
                authService.signOut();
            }

            return throwError(error);
        }),
    );
};
