/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

const user = localStorage.getItem('loggedUser');
const userData = JSON.parse(user);

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:home',
        link : '/dashboard'
    },
    {
        id   : 'users',
        title: 'Usuarios',
        type : 'basic',
        icon : 'heroicons_outline:user-group',
        link : '/users',
        hidden: () => {
            return !userData.permissions.includes('LIST_USERS');
        }
    },
    {
        id   : 'accounts',
        title: 'Cuentas',
        type : 'basic',
        icon : 'heroicons_outline:squares-2x2',
        link : '/accounts',
        hidden: () => {
            return !userData.permissions.includes('READ_ACCOUNTS');
        }
    },    
    {
        id   : 'projects',
        title: 'Proyectos',
        type : 'basic',
        icon : 'heroicons_outline:clipboard-document-check',
        link : '/projects',
        hidden: () => {
            return !userData.permissions.includes('READ_PROJECTS');
        }
    },
    {
        id   : 'domains',
        title: 'Dominios',
        type : 'basic',
        icon : 'mat_outline:dns',
        link : '/domains',
        hidden: () => {
            return !userData.permissions.includes('READ_DOMAINS');
        }
    },
    {
        id   : 'sensia',
        title: 'Sensia',
        type : 'basic',
        icon : 'mat_outline:apps',
        link : '/sensia'
    },
    {
        id   : 'sensia-audit',
        title: 'Sensia audits',
        type : 'basic',
        icon : 'heroicons_outline:shield-check',
        link : '/sensia'
    }    
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:home',
        link : '/dashboard'
    },
    {
        id   : 'users',
        title: 'Usuarios',
        type : 'basic',
        icon : 'heroicons_outline:user-group',
        link : '/users',
        hidden: () => {
            return !userData.permissions.includes('LIST_USERS');
        }
    },
    {
        id   : 'accounts',
        title: 'Cuentas',
        type : 'basic',
        icon : 'heroicons_outline:squares-2x2',
        link : '/accounts',
        hidden: () => {
            return !userData.permissions.includes('READ_ACCOUNTS');
        }
    },    
    {
        id   : 'projects',
        title: 'Proyectos',
        type : 'basic',
        icon : 'heroicons_outline:clipboard-document-check',
        link : '/projects',
        hidden: () => {
            return !userData.permissions.includes('READ_PROJECTS');
        }
    },
    {
        id   : 'domains',
        title: 'Dominios',
        type : 'basic',
        icon : 'mat_outline:dns',
        link : '/domains',
        hidden: () => {
            return !userData.permissions.includes('READ_DOMAINS');
        }
    },
    {
        id   : 'sensia',
        title: 'Sensia',
        type : 'basic',
        icon : 'mat_outline:apps',
        link : '/sensia',
        hidden: () => {
            return !userData.permissions.includes('MANAGE_SCHEMAS');
        }
    },
    {
        id   : 'sensia-audit',
        title: 'Sensia audits',
        type : 'basic',
        icon : 'heroicons_outline:shield-check',
        link : '/sensia-audit',
        hidden: () => {
            return !userData.permissions.includes('DO_AUDITS');
        }
    }          
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example3',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example4',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
